<template>
  <page-header :title="title" :breadcrumbs="pathMatch">
    <el-form
      :model="formData"
      :rules="paramsRules"
      ref="paramsForm"
      class="form-wrap"
    >
      <el-form-item>
        <h4>请填写参数相关信息</h4>
      </el-form-item>
      <el-form-item label="智能自检周期 / 天" prop="smart_cycle">
        <el-input
          v-model="formData.smart_cycle"
          placeholder="请设置自动巡检周期"
        />
        <span class="form-item-tips">若实时自检，请设置为0</span>
      </el-form-item>
      <el-form-item label="油机例行检查周期 / 月" prop="tanker_cycle">
        <el-input
          v-model="formData.tanker_cycle"
          placeholder="请设置例行检查周期"
        />
        <span class="form-item-tips">检查周期自上次检查日期开始计算</span>
      </el-form-item>
      <el-form-item style="text-align: center">
        <el-button type="primary" @click="handleSubmit">保存</el-button>
      </el-form-item>
    </el-form>

    <!-- :rules="rules" -->
    <ele-form
      v-if="false"
      class="form-wrap"
      v-model="RowInfo"
      :form-desc="formDesc"
      ref="form"
      labelPosition="top"
      :isShowSubmitBtn="true"
      submitBtnText="保存"
      :isShowBackBtn="false"
      :span="24"
      :request-fn="handleSubmit"
      :isShowErrorNotify="false"
    >
      <el-form-item>
        <h4>请填写参数相关信息</h4>
      </el-form-item>
    </ele-form>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import { getParamsData, saveParamsData } from '@/api/system';

export default {
  components: {
    PageHeader
  },
  data() {
    const _rules = [
      { required: true, message: ' ' },
      { pattern: /^[1-9][0-9]{0,8}$/, message: '请输入正整数' }
    ];
    return {
      formData: {
        smart_cycle: '',
        tanker_cycle: ''
      },
      paramsRules: {
        smart_cycle: [
          { required: true, trigger: 'blur', message: '请设置自动巡检周期' }
        ],
        tanker_cycle: [
          { required: true, trigger: 'blur', message: '请设置例行检查周期' }
        ]
      },

      formDesc: {
        smart_cycle: {
          type: 'input',
          layout: 24,
          label: '智能自检周期 / 天',
          rules: _rules
        },
        tanker_cycle: {
          type: 'input',
          layout: 24,
          label: '油机例行检查周期 / 月',
          rules: _rules
        }
        // oiltaking_sub: {
        //   type: 'input',
        //   layout: 24,
        //   label: '上报进油量和采集进油量偏差(L)',
        //   rules: _rules
        // },
        // oilout_sub: {
        //   type: 'input',
        //   layout: 24,
        //   label: '销售油量和采集出油量偏差(L)',
        //   rules: _rules
        // },
        // oiltanking_time: {
        //   type: 'input',
        //   layout: 24,
        //   label: '上报进油时间最多滞后(h)',
        //   rules: _rules
        // }
      },
      RowInfo: {}
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.getInfo();
  },
  methods: {
    getInfo() {
      getParamsData()
        .then(res => {
          const { code, data, msg } = res;
          console.log(data);
          if (code === 0) {
            this.formData = {
              ...data
            };
          } else {
            this.$message({
              message: msg || '数据请求失败!',
              type: 'error'
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleSubmit() {
      const _this = this;
      this.$refs['paramsForm'].validate(valid => {
        if (valid) {
          let params = {
            ..._this.formData
          };

          saveParamsData(params).then(result => {
            const { code, msg } = result;
            if (code === 0) {
              this.$message({
                message: '保存成功',
                type: 'success'
              });
            } else {
              this.$message({
                message: msg,
                type: 'error'
              });
            }
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.form-wrap {
  width: 400px;
  margin: auto;
}

.form-item-tips {
  color: #f8150d;
  font-size: 12px;
}
</style>
