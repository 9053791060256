import request from '@/utils/request';

export function getParamsData() {
  return request({
    url: '/params/get_detail',
    methods: 'get'
  });
}

export function saveParamsData(params) {
  return request({
    url: '/params/add_save',
    methods: 'get',
    params
  });
}
